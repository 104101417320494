import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Button, Slider, Dialog, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const ImageUploadBox = ({ image, setImage }) => {
    const [uploadedImage, setUploadedImage] = useState(null); // Holds the uploaded image
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [openCropModal, setOpenCropModal] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadedImage(reader.result);
                setOpenCropModal(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const cropImage = async () => {
        if (uploadedImage && croppedAreaPixels) {
            const croppedImage = await getCroppedImage(uploadedImage, croppedAreaPixels);
            setImage(croppedImage);
            setOpenCropModal(false);
        }
    };

    const getCroppedImage = (imageSrc, crop) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = crop.width;
                canvas.height = crop.height;

                ctx.drawImage(
                    image,
                    crop.x,
                    crop.y,
                    crop.width,
                    crop.height,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                canvas.toBlob((blob) => {
                    const fileUrl = URL.createObjectURL(blob);
                    resolve(fileUrl);
                }, "image/jpeg");
            };

            image.onerror = (error) => reject(error);
        });
    };

    return (
        <Box>
            {/* Image Upload Box */}
            <Box
                sx={{
                    width: "100%",
                    maxWidth: isMobile ? "150px" : "200px",
                    height: isMobile ? "120px" : "150px",
                    border: "2px dashed gray",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    backgroundColor: "#f9f9f9",
                    position: "relative",
                    overflow: "hidden",
                    margin: isMobile ? "0 auto" : "",
                }}
                onClick={() => document.getElementById("file-input").click()}
            >
                {!image ? (
                    <>
                        <AddPhotoAlternateIcon
                            sx={{
                                fontSize: isMobile ? "30px" : "40px",
                                color: "gray",
                            }}
                        />
                        <Typography
                            variant="body2"
                            color="gray"
                            sx={{
                                fontSize: isMobile ? "12px" : "14px",
                                textAlign: "center",
                            }}
                        >
                            Upload Photo
                        </Typography>
                    </>
                ) : (
                    <img
                        src={image}
                        alt="Uploaded"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}
                    />
                )}
                <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                />
            </Box>

            {/* Crop Modal */}
            <Dialog
                open={openCropModal}
                onClose={() => setOpenCropModal(false)}
                fullWidth
                maxWidth={isMobile ? "xs" : "sm"}
                PaperProps={{
                    sx: {
                        height: isMobile ? "70%" : "auto",
                    },
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                        height: isMobile ? "60%" : "400px",
                        background: "#333",
                    }}
                >
                    <Cropper
                        image={uploadedImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 7}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </Box>
                <Box
                    p={2}
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "stretch" : "center"}
                    justifyContent={isMobile ? "center" : "space-between"}
                >
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e, zoom) => setZoom(zoom)}
                        sx={{ width: isMobile ? "100%" : "70%", mb: isMobile ? 2 : 0 }}
                    />
                    <Button
                        variant="contained"
                        onClick={cropImage}
                        fullWidth={isMobile}
                    >
                        Crop & Save
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
};

export default ImageUploadBox;
