import React, { useState } from "react";
import { checkEndGame } from "./gameLogic";
import Confetti from "react-confetti";
import { Box, Button, Typography, Modal, Select, FormControl, InputLabel } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "./css/tic.css";

const boardDefault = new Array(9).fill("");

export default function TicTacToe() {
  const [board, setBoard] = useState(boardDefault);
  const [gameStatus, setGameStatus] = useState("active");
  const [gameType, setGameType] = useState("AI");
  const [turn, setTurn] = useState("X");
  const [winner, setWinner] = useState("");

  const hardAiMove = (board) => {
    const minimax = (board, depth, isMaximizing) => {
      const winner = checkEndGame(board);
      if (winner === "O") return 10 - depth;
      if (winner === "X") return depth - 10;
      if (!board.includes("")) return 0;

      if (isMaximizing) {
        let maxEval = -Infinity;
        for (let i = 0; i < board.length; i++) {
          if (!board[i]) {
            board[i] = "O";
            const evaluation = minimax(board, depth + 1, false);
            board[i] = "";
            maxEval = Math.max(maxEval, evaluation);
          }
        }
        return maxEval;
      } else {
        let minEval = Infinity;
        for (let i = 0; i < board.length; i++) {
          if (!board[i]) {
            board[i] = "X";
            const evaluation = minimax(board, depth + 1, true);
            board[i] = "";
            minEval = Math.min(minEval, evaluation);
          }
        }
        return minEval;
      }
    };

    let bestScore = -Infinity;
    let move = -1;
    for (let i = 0; i < board.length; i++) {
      if (!board[i]) {
        board[i] = "O";
        const score = minimax(board, 0, false);
        board[i] = "";
        if (score > bestScore) {
          bestScore = score;
          move = i;
        }
      }
    }
    return move;
  };


  const onCellClick = (index) => {
    if (board[index] || gameStatus !== "active") {
      return;
    }

    let newBoard = [...board];
    newBoard[index] = turn;
    setBoard(newBoard);
    const nextTurn = turn === "X" ? "O" : "X";
    setTurn(nextTurn);

    const winner = checkEndGame(newBoard);
    if (winner) {
      setGameStatus("completed");
      setWinner(winner);
      return;
    } else if (!newBoard.includes("")) {
      setGameStatus("draw");
      return;
    }


    if (gameType === "AI" && nextTurn === "O") {
      setTimeout(() => {
        const aiIndex = hardAiMove(newBoard);
        if (aiIndex !== null) {
          newBoard[aiIndex] = "O";
          setBoard(newBoard);
          setTurn("X");
          const aiWinner = checkEndGame(newBoard);
          if (aiWinner) {
            setGameStatus("completed");
            setWinner(aiWinner);
          } else if (!newBoard.includes("")) {
            setGameStatus("draw");
          }
        }
      }, 500);
    }
  };

  const onReset = () => {
    setTurn("X");
    setWinner("");
    setBoard(boardDefault);
    setGameStatus("active");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        m: "auto",
        maxWidth: "100%",
      }}
    >
      {winner && <Confetti />}

      <Modal open={!!winner} onClose={onReset}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#161e3b",
            borderRadius: "16px",
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            textAlign: "center",
            width: { xs: "90%", sm: "auto" },
          }}
        >
          <Typography
            variant="h4"
            color="success.main"
            fontWeight="bold"
            gutterBottom
          >
            🎉 {winner} Wins! 🎉
          </Typography>
          <Typography variant="h5" color="#fff" gutterBottom>
            What an incredible game! Congratulations, {winner}!
          </Typography>
          <Button
            variant="contained"
            onClick={onReset}
            sx={{
              mt: 2,
              py: 2,
              backgroundColor: "#14d9fd",
              "&:hover": { backgroundColor: "#12b4d1" },
              fontSize: { xs: "1rem", sm: "1.25rem" },
              fontWeight: "bold",
            }}
          >
            Play Again
          </Button>
        </Box>
      </Modal>

      <Modal open={gameStatus === "draw"} onClose={onReset}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2A3335",
            borderRadius: "16px",
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            textAlign: "center",
            width: { xs: "90%", sm: "auto" },
          }}
        >
          <Typography
            variant="h4"
            color="warning.main"
            fontWeight="bold"
            gutterBottom
          >
            🤝 It's a Draw! 🤝
          </Typography>
          <Typography variant="h5" color="#fff" gutterBottom>
            No one wins this time. Try again!
          </Typography>
          <Button
            variant="contained"
            onClick={onReset}
            sx={{
              mt: 2,
              py: 2,
              backgroundColor: "#14d9fd",
              "&:hover": { backgroundColor: "#12b4d1" },
              fontSize: { xs: "1rem", sm: "1.25rem" },
              fontWeight: "bold",
            }}
          >
            Play Again
          </Button>
        </Box>
      </Modal>

      <Select
        value={gameType}
        onChange={(e) => setGameType(e.target.value)}
        sx={{
          color: "#fff",
          backgroundColor: "#1e293b",
          borderRadius: "4px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#494957",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#14d9fd",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#14d9fd",
          },
          ".MuiSvgIcon-root": {
            color: "#fff",
          },
          padding: "8px",
        }}
        native
      >
        <option value={"AI"} style={{ backgroundColor: "#000", color: "#fff" }}>
          Play with AI
        </option>
        <option value={"SWAP"} style={{ backgroundColor: "#000", color: "#fff" }}>
          Swap
        </option>
        <option value={"MULTI"} disabled style={{ backgroundColor: "#000", color: "#fff" }}>
          Play with Multiplayer
        </option>
      </Select>

      <Typography
        variant="h4"
        sx={{
          mt: 2,
          fontWeight: "bold",
          color: "#fff",
          textAlign: "center",
        }}
      >
        Turn:{" "}
        <Box
          component="span"
          sx={{
            color: turn === "X" ? "primary.main" : "secondary.main",
            fontWeight: "bold",
          }}
        >
          {turn}
        </Box>
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 2,
          mt: 4,
          p: 2,
          border: 2,
          borderRadius: 4,
          borderColor: "#fff",
          bgcolor: "#2C2C2C",
          boxShadow: 3,
          width: { xs: "100%", sm: "50%", md: "30%" },
          height: { xs: "100%", sm: "50%", md: "40%" },
        }}
      >
        {board.map((cell, index) => (
          <Box
            key={index}
            onClick={() => onCellClick(index)}
            sx={{
              width: "100%",
              aspectRatio: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: { xs: 24, sm: 32 },
              fontWeight: "bold",
              border: 1,
              borderColor: "#fff",
              borderRadius: 2,
              cursor: "pointer",
              transition: "transform 0.2s",
              "&:hover": { transform: "scale(1.1)", bgcolor: "action.hover" },
              color: cell === "X" ? "primary.main" : "secondary.main",
            }}
          >
            {cell}
          </Box>
        ))}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<RestartAltIcon />}
          onClick={onReset}
        >
          RESET
        </Button>
      </Box>
    </Box>
  );
}
