export const toolsData = [
  {
    id: 1,
    img: "https://www.gaflaquiz.xyz/wp-content/themes/gaflaquiz/images/secret-message-front.png",
    name: "Secret Message",
    color: "250,56,154,1",
    href: "/secret-message",
  },
  {
    id: 2,
    img: "https://www.gaflaquiz.xyz/wp-content/themes/gaflaquiz/images/friendship-dare.png",
    name: "Friend Quiz",
    color: "250,127,56,1",
    href: "",
  },
  {
    id: 3,
    img: "https://www.gamesver.com/wp-content/uploads/2022/02/tic-tac-toe-board-game-concept.jpg",
    name: "Tic-Tac-Toe",
    color: "250,71,56,1",
    href: "/tictactoe",
  },
  {
    id: 4,
    img: "https://files.123freevectors.com/wp-content/original/180181-hand-drawn-scribble-sketch-vector-image.jpg",
    name: "Scribbles",
    color: "153,56,250,1",
    href: "/skribbl",
  },
  {
    id: 5,
    img: "https://www.diabetes.co.uk/wp-content/uploads/2019/01/iStock-528072248-scaled.jpg",
    name: "BMI Calculator",
    color: "4,82,39,1",
    href: "",
  },
];
