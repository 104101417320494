import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import TicTacToe from "../components/game/categories/tic-tac/TicTacToe";
import Skribbl from "../components/game/categories/scribble/Game";
import FileConverter from "../components/fileconverter/FileConverter";
import SecretMessage from "../pages/SecretMessage";
import LinkDisplay from "../components/secretmessage/LinkDisplay";
import SecretById from "../pages/SecretById";
import { MarriageBiodataHome } from "../components/MarriageBioData/MarriageBiodataHome";
import QRCodeGeneration  from "../components/qrcodegeneration/QRCodeGeneration";
import Home from "../pages/Home";
import Game from "../pages/Game";

import FileConverters from "../components/fileconverter/FileConverters";
const Router = () => {
  const [link, setLink] = useState("");
  const [userId, setUserId] = useState(null);
  const storedLink = localStorage.getItem("link");

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const handleLinkGenerated = (generatedLink) => {
    setLink(generatedLink);
    localStorage.setItem("link", generatedLink);
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/secret"
          element={
            userId || link ? (
              <LinkDisplay link={link || storedLink} />
            ) : (
              <SecretMessage onLinkGenerated={handleLinkGenerated} />
            )
          }
        />
        <Route path="/tictactoe" element={<TicTacToe />} />
        <Route path="/skribbl" element={<Skribbl />} />
        <Route path="/converter" element={<FileConverters/>} />
        <Route
          path="/secret-message"
          element={
            userId || link ? (
              <LinkDisplay link={link || storedLink} />
            ) : (
              <SecretMessage onLinkGenerated={handleLinkGenerated} />
            )
          }
        />
        <Route path="/secret/:id" element={<SecretById />} />
        <Route path="/game" element={<Game />} />
        <Route path="/mbiodata" element={<MarriageBiodataHome />} />
        <Route path="/qrcodegenerator" element={<QRCodeGeneration />} />
      </Routes>
    </>
  );
};

export default Router;
