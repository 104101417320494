import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { ImageToPDF } from "./ImageToPDF";
import { PdfToImages } from "./PdfToImages";
import { compressFile, PDFtoWord, WordToPDF } from "../../services/Convertor";
import { fileConvertorConstants } from "../common/Constants";
import { compressImage } from "./compressImage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
};

const downloadFile = (response, fileName, mimeType) => {
  if (!response) {
    console.error("Invalid response passed to downloadFile", response);
    return;
  }

  let url;
  if (typeof response === "string" && response.startsWith("data:image")) {
    url = response; // Directly use Base64 URL for images
  } else if (response instanceof Blob) {
    url = URL.createObjectURL(response); // Create URL for Blob
  } else {
    console.error("Unsupported response type:", typeof response);
    return;
  }

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();

  if (url && !url.startsWith("data:image")) {
    URL.revokeObjectURL(url);
  }
};

const validateFileTypes = (files, allowedFormats) => {


  if (files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      if (!allowedFormats.includes(files[i].type)) {
        return `The selected file type (${files[i].type}) is not supported for this operationn.`;
      }
    }
  } else {
    if (!allowedFormats.includes(files.type)) {
      return `The selected file type (${files.type}) is not supported for this operation.`;
    }
  }
  return null; // All files are valid
};

export default function Modals({ open, setOpen, operationDetails }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleProceed = async () => {
    if (!selectedFile) {
      alert("Please select a file to proceed!");
      return;
    }
    const errorMessage = validateFileTypes(selectedFile, operationDetails.allowedFormats);
    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    setLoading(true);
    try {
      let response, fileName, mimeType;

      switch (operationDetails.id) {
        case "compressImage":
          response = await compressImage(selectedFile);
          fileName = `${selectedFile.name.replace(/\.[^/.]+$/, "")}.compressed.png`;
          mimeType = response.type || "image/png";
          break;

        case "compressText":
          response = await compressFile(selectedFile);
          fileName = `${selectedFile.name.replace(/\.[^/.]+$/, "")}.compressed.txt`;
          mimeType = response.type || "application/octet-stream";
          break;

        case "pdfToWord":
          response = await PDFtoWord(selectedFile);
          fileName = selectedFile.name.replace(/\.pdf$/, ".docx");
          mimeType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document.docx";
          break;

        case "wordToPdf":
          response = await WordToPDF(selectedFile);
          fileName = selectedFile.name.replace(/\.docx$/, ".pdf");
          mimeType = "application/pdf";
          break;

        case "imageToPdf":
          response = await ImageToPDF(selectedFile);
          const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, "");
          fileName = `${fileConvertorConstants.PDF_TO_IMAGE_DOWNLOAD_FILE_NAME}_${timestamp}.pdf`;
          mimeType = "application/pdf";
          break;

        case "pdfToImage":
          const images = await PdfToImages(selectedFile);
          if (!images || images.length === 0) {
            console.error("No images were generated from the PDF.", images);
            alert("No images were generated from the PDF.");
            return;
          }

          images.forEach(({ url, pageNumber }, index) => {
            if (!url) {
              console.error(`Missing URL for page ${pageNumber} at index ${index}`);
              return;
            }

            const fileName = `page_${pageNumber}_${new Date().toISOString().replace(/[-:.TZ]/g, "")}.png`;
            downloadFile(url, fileName, "image/png");
          });

          alert("Images generated and downloaded successfully!");
          handleClose();
          break;

        default:
          console.log(`Unsupported operation: ${operationDetails.name}`);
          handleClose();
          return;
      }

      downloadFile(response, fileName, mimeType);
      console.log(`${operationDetails.name} completed and file downloaded successfully!`);
    } catch (error) {
      console.error(`Error during ${operationDetails.name}:`, error);
      alert(`An error occurred while processing the file. Please try again.`);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(operationDetails.mulitpleFilesUpload ? event.target.files : event.target.files[0]);
  };

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="operation-modal-title"
      aria-describedby="operation-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="operation-modal-title"
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          {operationDetails.name}
        </Typography>
        <Typography
          id="operation-modal-description"
          variant="body1"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Select a file to proceed with {operationDetails.name}.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
          <input
            type="file"
            onChange={handleFileChange}
            // fullWidth
            style={{ mt: 3 }}
            disabled={loading}
            accept={operationDetails.allowedFormats.join(", ")}
            multiple={operationDetails.mulitpleFilesUpload}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 4,
            gap: 2,
            flexDirection: { xs: "column", sm: "row" }, // Stack buttons on small screens
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            disabled={loading}
            fullWidth={true} // Ensure button stretches on mobile
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProceed}
            disabled={loading}
            fullWidth={true} // Ensure button stretches on mobile
          >
            {loading ? "Processing..." : "Proceed"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
