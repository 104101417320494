import { jsPDF } from "jspdf";
export async function ImageToPDF(images) {
    const pdf = new jsPDF();

    for (let i = 0; i < images.length; i++) {
        const imageFile = images[i];


        if (!(imageFile instanceof File)) {
            console.error("Invalid file type passed to createObjectURL:", imageFile);
            return;
        }

        console.log(`Processing file: ${imageFile.name}, type: ${imageFile.type}`);

        const imageUrl = URL.createObjectURL(imageFile);

        const img = new Image();
        img.src = imageUrl;

        await new Promise((resolve) => {
            img.onload = () => {
                const width = pdf.internal.pageSize.getWidth();
                const height = (img.height / img.width) * width;
                pdf.addImage(img, "JPEG", 0, 0, width, height);

                if (i < images.length - 1) pdf.addPage();

                URL.revokeObjectURL(imageUrl);
                resolve();
            };
        });
    }

    return pdf.output("blob");
}
