import React from "react";
import { Box } from "@mui/material";
import ToolsSlider from "../components/home/ToolsSlider";

const Home = () => {
  return (
    <Box>
      <ToolsSlider />
    </Box>
  );
};

export default Home;
