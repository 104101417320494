import React, { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Button,
    IconButton,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, Delete, Add } from "@mui/icons-material";
import { useTheme, useMediaQuery } from "@mui/material";
import MarriageBiodataTemplate from "./MarriageBiodataTemplate";
import ImageUploadBox from "../common/ImageUploadBox";
import { ArrowForward } from "@mui/icons-material";
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import { localStoragekey, marriageBiodataInitialValue } from "../common/Constants";
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';

const MarriageBiodataForm = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const localStorageData = localStorage.getItem(localStoragekey.MARRIAGE_BIODATA);
    const [image, setImage] = useState(localStorageData ? JSON.parse(localStorageData)?.savedImage : null);
    const [showTemplate, setShowTemplate] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [sections, setSections] = useState(localStorageData ? JSON.parse(localStorageData)?.savedSections : marriageBiodataInitialValue);

    const generateBiodata = () => {
        setShowTemplate(true);
    };

    useEffect(() => {
        const savedData = localStorage.getItem(localStoragekey.MARRIAGE_BIODATA);
        if (savedData) {
            const { savedSections, savedImage } = JSON.parse(savedData);
            setSections(savedSections || []);
            setImage(savedImage || null);
        }
    }, []);

    useEffect(() => {
        const dataToSave = { savedSections: sections, savedImage: image };
        localStorage.setItem(localStoragekey.MARRIAGE_BIODATA, JSON.stringify(dataToSave));
    }, [sections, image]);

    const resetData = () => {
        setSections(marriageBiodataInitialValue);
        setImage(null);
        localStorage.removeItem(localStoragekey.MARRIAGE_BIODATA);
        setResetDialogOpen(false);
    };

    const openResetDialog = () => setResetDialogOpen(true);
    const closeResetDialog = () => setResetDialogOpen(false);

    const handleFieldChange = (sectionId, index, newValue) => {
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        fields: section.fields.map((field, i) =>
                            i === index ? { ...field, value: newValue } : field
                        ),
                    }
                    : section
            )
        );
    };

    const handleFieldLabelChange = (sectionId, index, newLabel) => {
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        fields: section.fields.map((field, i) =>
                            i === index ? { ...field, label: newLabel } : field
                        ),
                    }
                    : section
            )
        );
    };

    const handleSectionNameChange = (sectionId, newName) => {
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId ? { ...section, name: newName } : section
            )
        );
    };

    const addField = (sectionId) => {
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        fields: [...section.fields, { label: "New Field", value: "" }],
                    }
                    : section
            )
        );
    };

    const deleteField = (sectionId, index) => {
        setSections((prev) =>
            prev.map((section) =>
                section.id === sectionId
                    ? {
                        ...section,
                        fields: section.fields.filter((_, i) => i !== index),
                    }
                    : section
            )
        );

    };

    const moveField = (sectionId, fromIndex, toIndex) => {
        setSections((prev) =>
            prev.map((section) => {
                if (section.id === sectionId) {
                    const fields = [...section.fields];
                    const [movedField] = fields.splice(fromIndex, 1);
                    fields.splice(toIndex, 0, movedField);
                    return { ...section, fields };
                }
                return section;
            })
        );
    };

    const moveSection = (fromIndex, toIndex) => {
        setSections((prev) => {
            const newSections = [...prev];
            const [movedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, movedSection);
            return newSections;
        });
    };

    const addSection = () => {
        setSections((prev) => [
            ...prev,
            {
                id: Date.now(),
                name: "New Section",
                fields: [],
            },
        ]);
    };

    const deleteSection = (sectionIndex) => {
        if (sections.length != 1) {
            setSections((prev) => prev.filter((_, index) => index !== sectionIndex));
        }
        else {
            alert("Atleast one section is required, if you need to retrieve all the sections you deleted, reset it")
        }
    };

    if (showTemplate) {
        return <MarriageBiodataTemplate setShowTemplate={setShowTemplate} sections={sections} image={image} />;
    }

    return (
        <Box p={3}>
            <Box mb={3}>

                <Typography variant="h6" mb={2}>
                    Profile Picture
                </Typography>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={openResetDialog}
                    sx={{ width: isMobile ? "100%" : "20%", float: "right", position: "relative", top: -40 }}
                    endIcon={<ClearAllRoundedIcon />}
                >
                    Reset Data
                </Button>
                <ImageUploadBox image={image} setImage={setImage} />

            </Box>
            {sections.map((section, sectionIndex) => (
                <Box key={section.id} mb={3} border={1} p={2} borderRadius={2}>
                    <Grid container mb={2} alignItems="center" spacing={2}>
                        <Grid item xs={8} sm={10}>
                            <TextField
                                fullWidth
                                label="Section Name"
                                value={section.name}
                                onChange={(e) =>
                                    handleSectionNameChange(section.id, e.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <IconButton
                                disabled={sectionIndex === 0}
                                onClick={() => moveSection(sectionIndex, sectionIndex - 1)}
                                sx={{ padding: 0.1 }}
                            >
                                <ArrowUpward />
                            </IconButton>
                            <IconButton
                                disabled={sectionIndex === sections.length - 1}
                                onClick={() => moveSection(sectionIndex, sectionIndex + 1)}
                                sx={{ padding: 0.1 }}
                            >
                                <ArrowDownward />
                            </IconButton>
                            <IconButton onClick={() => deleteSection(sectionIndex)} sx={{ padding: 0.1 }}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {section.fields.map((field, fieldIndex) => (
                        <Grid container spacing={2} mb={!isMobile && 2} alignItems="center" key={fieldIndex}>
                            <Grid item xs={10} sm={4}>
                                <TextField
                                    fullWidth
                                    value={field.label}
                                    placeholder="Field Label"
                                    onChange={(e) =>
                                        handleFieldLabelChange(section.id, fieldIndex, e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={10} sm={4}>
                                <TextField
                                    fullWidth
                                    value={field.value}
                                    placeholder={`${field.label} Value`}
                                    onChange={(e) =>
                                        handleFieldChange(section.id, fieldIndex, e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={2} sm={4}>
                                <IconButton
                                    disabled={fieldIndex === 0}
                                    onClick={() => moveField(section.id, fieldIndex, fieldIndex - 1)}
                                    sx={{
                                        padding: 0.4,
                                        position: "relative",
                                        top: isMobile ? "-60px" : "0px", // Apply top adjustment only on mobile
                                    }}
                                >
                                    <ArrowUpward />
                                </IconButton>
                                <IconButton
                                    disabled={fieldIndex === section.fields.length - 1}
                                    onClick={() => moveField(section.id, fieldIndex, fieldIndex + 1)}
                                    sx={{
                                        padding: 0.4,
                                        position: "relative",
                                        top: isMobile ? "0px" : "0px", // Optional: No top change for larger screens
                                    }}
                                >
                                    <ArrowDownward />
                                </IconButton>
                                <IconButton
                                    onClick={() => deleteField(section.id, fieldIndex)}
                                    sx={{
                                        padding: 0.4,
                                        position: "relative",
                                        top: isMobile ? "-75px" : "0px", // Apply top adjustment only on mobile
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}

                    <Button
                        startIcon={<Add />}
                        onClick={() => addField(section.id)}
                        sx={{ mt: 2, mr: 2 }}
                    >
                        Add Field
                    </Button>
                    <Button
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => deleteSection(sectionIndex)}
                        sx={{ mt: 2 }}
                    >
                        Delete Section
                    </Button>
                </Box>
            ))}
            <Button variant="contained" startIcon={<Add />} onClick={addSection}>
                Add Section
            </Button>
            <br />
            <Button
                variant="contained"
                color="primary"
                onClick={generateBiodata}
                sx={{ mt: 2, width: isMobile ? "100%" : "20%" }}
                endIcon={<DoubleArrowOutlinedIcon />}
            >
                Generate Biodata
            </Button>
            <br />
            <Button
                variant="outlined"
                color="error"
                onClick={openResetDialog}
                sx={{ mt: 2, width: isMobile ? "100%" : "20%" }}
                endIcon={<ClearAllRoundedIcon />}
            >
                Reset Data
            </Button>
            <Dialog
                open={resetDialogOpen}
                onClose={closeResetDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Reset Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to clear all the details stored on
                        your device? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeResetDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={resetData} color="error" variant="outlined">
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MarriageBiodataForm;
