import axios from "axios";
import { DataURls } from "../components/common/urls";

const BASE_URL = "https://rapidgameshub.onrender.com";

// Generate Link

export const generateSecretMessageLink = async (name) => {
  try {
    const response = await axios.post(
      `${BASE_URL + DataURls.POST_GENERATE_MESSAGE}`,
      {
        name,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.code === 200 && response.data.user_id) {
      return response.data.user_id;
    } else {
      throw new Error("Invalid response from API");
    }
  } catch (error) {
    console.error("Error in API:", error.message);
    throw new Error("Failed to generate link.");
  }
};

// Get Name by user id

export const getNameByUserId = async (userId) => {
  try {
    const response = await axios.get(
      `${BASE_URL + DataURls.GET_NAME_BY_ID.replace("${user_id}", userId)}`, 
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.code === 200 && response.data.data?.name) {
      return response.data.data.name;
    } else {
      throw new Error("Invalid response from API");
    }
  } catch (error) {
    console.error("Error fetching name by ID:", error);
    throw new Error("Failed to fetch user details.");
  }
};

// Send message

export const sendMessageToUser = async (userId, message) => {
  try {
    const response = await axios.post(
      `${BASE_URL + DataURls.POST_SEND_MESSAGE}`,
      {
        user_id: userId,
        message: message,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.code === 200) {
      return true;
    } else {
      throw new Error("Failed to send message.");
    }
  } catch (error) {
    console.error("Error sending message:", error);
    throw new Error("Failed to send message.");
  }
};

// Get messages

export const fetchMessagesAPI = async (userId) => {
  try {
    const response = await axios.get(
      `${BASE_URL + DataURls.GET_MESSAGES.replace("${user_id}", userId)}`,
      { headers: { "Content-Type": "application/json" } }
    );

    if (response.data.code === 200) {
      return response.data.messages || [];
    } else {
      throw new Error("Failed to fetch messages");
    }
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};

// Delete message

export const deleteMessageAPI = async (messageId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL + DataURls.DELETE_MESSAGE.replace("${message_id}", messageId)}`,
      { headers: { "Content-Type": "application/json" } }
    );

    if (response.data.code === 200) {
      if (response.data.message === "Message deleted successfully") {
        return true;
      }
    }
    throw new Error("Failed to delete message");
  } catch (error) {
    console.error("Error deleting message:", error);
    throw error;
  }
};

// Reset User

export const resetUserAPI = async (userId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL + DataURls.DELETE_REST_USER.replace("${user_id}", userId)}`,
      { headers: { "Content-Type": "application/json" } }
    );

    if (response.data.code === 200) {
      return true;
    } else {
      throw new Error("Failed to reset user");
    }
  } catch (error) {
    console.error("Error resetting user:", error);
    throw error;
  }
};
