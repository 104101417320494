import React, { useState } from "react";
import Canvas from "./Canvas";
import UndoIcon from "@mui/icons-material/Undo";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Typography,
  Slider,
  TextField,
  IconButton,
} from "@mui/material";

function Game() {
  const [drawing, setDrawing] = useState(false);
  const [brushColor, setBrushColor] = useState("#000000");
  const [brushSize, setBrushSize] = useState(5);
  const [paths, setPaths] = useState([]);

  const undoLastStroke = () => {
    if (paths.length === 0) return;
    const updatedPaths = paths.slice(0, -1);
    setPaths(updatedPaths);
  };

  const clearCanvas = () => {
    setPaths([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={{ xs: 2, sm: 4 }}
      gap={3}
      color="white"
      sx={{
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        textAlign="center"
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        🎨 Draw the Word!
      </Typography>

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        gap={{ xs: 2, sm: 3 }}
        padding={2}
        bgcolor="grey.800"
        borderRadius={2}
        boxShadow={3}
        sx={{
          width: "100%",
          justifyContent: { sm: "space-between" },
          maxWidth: "600px",
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            Color:
          </Typography>
          <TextField
            type="color"
            value={brushColor}
            onChange={(e) => setBrushColor(e.target.value)}
            size="small"
            sx={{ width: 50 }}
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            Brush Size:
          </Typography>
          <Slider
            min={1}
            max={20}
            value={brushSize}
            onChange={(e, value) => setBrushSize(value)}
            valueLabelDisplay="auto"
            sx={{ width: { xs: 100, sm: 150 } }}
          />
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
          >
            {brushSize}px
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 5 }}>
          <IconButton
            onClick={undoLastStroke}
            color="primary"
            sx={{
              bgcolor: "yellow",
              "&:hover": { bgcolor: "yellow.dark" },
              color: "black",
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            <UndoIcon />
          </IconButton>

          <IconButton
            onClick={clearCanvas}
            color="secondary"
            sx={{
              bgcolor: "red",
              "&:hover": { bgcolor: "red.dark" },
              color: "#fff",
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      </Box>

      <Canvas
        drawing={drawing}
        setDrawing={setDrawing}
        brushColor={brushColor}
        brushSize={brushSize}
        paths={paths}
        setPaths={setPaths}
      />
    </Box>
  );
}

export default Game;
