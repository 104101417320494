

export const fileConvertorConstants = {
    IMAGE_TO_PDF_DOWNLOAD_FILE_NAME: "RapidHub PDF Converted",
    PDF_TO_IMAGE_DOWNLOAD_FILE_NAME: "RapidHub Image Converted",
}

export const localStoragekey = {
    MARRIAGE_BIODATA: "marriage_biodata",
    MARRIAGE_BIODATA: "marriage_biodata",
}

export const marriageBiodataInitialValue = [
    {
        id: Date.now(),
        name: "Personal Details",
        fields: [
            { label: "Full Name", value: "", required: true },
            { label: "Date of Birth", value: "", required: true },
            { label: "Place of Birth", value: "", required: true },
            { label: "Time of Birth", value: "" },
            { label: "Rashi (Zodiac Sign)", value: "" },
            { label: "Nakshatra (Star Sign)", value: "" },
            { label: "Height (in cm)", value: "" },
            { label: "Complexion", value: "" },
            { label: "Gotra", value: "" },
            { label: "Highest Education", value: "", required: true },
            { label: "University/College Name", value: "" },
            { label: "Current Job/Occupation", value: "" },
            { label: "Organization Name", value: "" },
            { label: "Annual Income", value: "" },
            { label: "Mother Tongue", value: "" },
            { label: "Caste", value: "", required: true },
            { label: "Sub Caste", value: "" },
            { label: "Manglik (Yes/No)", value: "" },
        ],
    },
    {
        id: Date.now() + 1,
        name: "Family Details",
        fields: [
            { label: "Father's Name", value: "" },
            { label: "Father's Occupation", value: "" },
            { label: "Mother's Name", value: "" },
            { label: "Mother's Occupation", value: "" },
            { label: "Total Brothers", value: "" },
            { label: "Married Brothers", value: "" },
            { label: "Total Sisters", value: "" },
            { label: "Married Sisters", value: "" },
            { label: "Family Type (Joint/Nuclear)", value: "" },
            { label: "Family Income (Annual)", value: "" },
        ],
    },
    {
        id: Date.now() + 2,
        name: "Contact Details",
        fields: [
            { label: "Contact Person", value: "", required: true },
            { label: "Contact Number", value: "", required: true },
            { label: "Email Address", value: "", required: true },
            { label: "Residential Address", value: "" },
        ],
    },
    {
        id: Date.now() + 3,
        name: "Partner Preferences",
        fields: [
            { label: "Preferred Age Range", value: "" },
            { label: "Preferred Height Range (in cm)", value: "" },
            { label: "Preferred Complexion", value: "" },
            { label: "Preferred Education Level", value: "" },
            { label: "Preferred Job/Occupation", value: "" },
            { label: "Preferred Annual Income", value: "" },
            { label: "Preferred Caste", value: "" },
            { label: "Preferred Sub Caste", value: "" },
            { label: "Preferred Gotra", value: "" },
            { label: "Manglik (Yes/No)", value: "" },
        ],
    },
];