import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Box,
} from "@mui/material";
import { generateSecretMessageLink } from "../services/ApiService";

const SecretMessage = ({ onLinkGenerated }) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const generateLink = async () => {
    if (!name.trim()) {
      setError("Name is required to generate a link.");
      return;
    }

    if (!agreedToTerms) {
      setError("You must agree to the terms and conditions.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const userId = await generateSecretMessageLink(name);
      console.log("Generated User ID:", userId);
      const generatedLink = `http://localhost:3000/secret/${userId}`;
      onLinkGenerated(generatedLink);
      localStorage.setItem("userId", userId);
    } catch (error) {
      console.error("Error in generateLink:", error.message);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 4, mt: { xs: "8", sm: 4 }, mb: 6 }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        Secret Message
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 800,
          width: "100%",
          mb: 2,
          borderRadius:"10px",
          border:"2px solid gray"
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: { xs: "1rem", sm: "1.44rem" } }}
        >
          Enter your Name, Create a Secret Message link, and Share it with your
          friends on WhatsApp, Facebook...
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: { xs: "1rem", sm: "1.44rem" } }}
        >
          Get unknown feedback from your friends, co-workers, and Fans.
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: { xs: "1rem", sm: "1.44rem" } }}
        >
          Once your friends send you a message, you will see the results on a
          Message board.
        </Typography>
      </Paper>
      <Box sx={{ maxWidth: 800, width: "100%", borderRadius: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={agreedToTerms}
              onChange={() => setAgreedToTerms(!agreedToTerms)}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
            >
              I agree to{" "}
              <a href="#" style={{ textDecoration: "underline" }}>
                Terms and conditions
              </a>{" "}
              of the website.
            </Typography>
          }
        />

        {error && (
          <Typography
            variant="body2"
            color="error"
            textAlign="center"
            gutterBottom
            sx={{ mt: 2 }}
          >
            {error}
          </Typography>
        )}

        <Button
          fullWidth
          variant="contained"
          onClick={generateLink}
          disabled={loading}
          sx={{
            mt: 2,
            py: 2,
            fontSize: { xs: "1rem", sm: "1.25rem" },
            fontWeight: "bold",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            "Generate your Link"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default SecretMessage;
