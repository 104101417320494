export const DataURls = {

    // SECRET_MESSAGE
    POST_GENERATE_MESSAGE: "/secret_message/generate",
    GET_NAME_BY_ID: "/secret_message/getNameById?user_id=${user_id}",
    POST_SEND_MESSAGE: "/secret_message/sendMessage",
    GET_MESSAGES: "/secret_message/getMessages?user_id=${user_id}",
    DELETE_MESSAGE: "/secret_message/deleteMessage?message_id=${message_id}",
    DELETE_REST_USER: "/secret_message/reset?user_id=${user_id}",

    // FILE_COMPRESS
    POST_FILE_COMPRESSION : "/fileCompress/fileCompress",

    // FILE_CONVERSIONS
    POST_CONVERSION_PDF_TO_WORD : "/fileConvertor/pdfToWord",
    POST_CONVERSION_PDF_TO_WORD : "/fileConvertor/wordToPdf",
}