import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const style = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "80%" : 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: isMobile ? "10px" : "20px",
  textAlign: "center",
});

export default function ComingSoonModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style(isMobile)}>
          <img
            src="https://static.vecteezy.com/system/resources/previews/002/187/723/large_2x/coming-soon-neon-signs-style-text-free-vector.jpg"
            alt="Coming Soon"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}
