import React from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  IconButton,
  Link,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const socialMediaLinks = {
  facebook: "#",
  twitter: "#",
  instagram: "#",
};

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "#10162d",
        color: "text.secondary",
        py: 2,
        borderTop: "1px solid",
        borderColor: "divider",
        p: { xs: 2, sm: 4 },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Logo Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white", mr: 1 }}
              >
                Rapid
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "text.secondary", ml: 1 }}
              >
                Hub
              </Typography>
            </Box>
          </Grid>

          {/* Spacer to push icons to the right */}
          <Grid item xs />

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
             <Link
              href="#"
              color="#FF5BAE"
              display="block"
              sx={{
                fontSize: "1rem", // Small size
                textDecoration: "none",
              
                "&:hover": { color: "#FFFFFF" }, // Change color on hover
              }}
            >
              Games
            </Link>
            <Link
              href="#"
              color="#FF5BAE"
              display="block"
              sx={{
                fontSize: "1rem", // Small size
                textDecoration: "none",
                "&:hover": { color: "#FFFFFF" }, // Change color on hover
                margin:"6px"
              }}
            >
              Tools
            </Link>
            <IconButton
              aria-label="Facebook"
              color="primary"
              component="a"
              href={socialMediaLinks.facebook}
              sx={{ "&:hover": { color: "#3b5998" } }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              color="primary"
              component="a"
              href={socialMediaLinks.twitter}
              sx={{ "&:hover": { color: "#1da1f2" } }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              aria-label="Instagram"
              color="primary"
              component="a"
              href={socialMediaLinks.instagram}
              sx={{ "&:hover": { color: "#e4405f" } }}
            >
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <Typography
          variant="body2"
          color="#fff"
          align="center"
          sx={{ pt: 4, fontSize: { xs: "0.75rem", sm: "1rem" } }}
        >
          © 2024 Company Co. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
