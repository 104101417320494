import React, { useEffect, useState,  } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getNameByUserId, sendMessageToUser } from "../services/ApiService";
import { Link } from "react-router-dom";

const SecretById = () => {
  const { id } = useParams();
  const UserId = id;
  // const UserId = localStorage.getItem("userId");
  const [name, setName] = useState("");
  const [thought, setThought] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUserName = async () => {
    try {
      const userName = await getNameByUserId(UserId);
      setName(userName);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (UserId) {
      fetchUserName();
    }
  }, [UserId]);

  const handleSendMessage = async () => {
    if (!thought.trim()) {
      setError("Please enter your thought.");
      return;
    }

    setError(""); 
    setSuccess(false);
    setLoading(true);

    try {
      await sendMessageToUser(UserId, thought);
      setSuccess(true);
      setThought("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: 4, px: 2 }}
    >
      <Typography variant="h4" fontWeight="bold" gutterBottom color="#fff">
        Secret Message - Let's play with {name || "a user"}
      </Typography>
      <Card
        sx={{
          width: "100%",
          maxWidth: 800,
          p: 3,
          mt: 6,
          mb: 8,
          boxShadow: 4,
          borderRadius: 2,
          backgroundColor: "#161e3b",
          color: "white",
        }}
      >
        <CardContent>
          {success ? (
            <Box textAlign="center">
              <Typography variant="h3" fontWeight="bold" gutterBottom>
                Thanks For Your Participation!
              </Typography>
              <Typography variant="h4" color="success" gutterBottom>
                Your message has been sent successfully!
              </Typography>
              <Typography variant="h6" color="#fff" gutterBottom>
                Click the button below to generate your own secret message link.
              </Typography>
              <Button
                onClick={() => (window.location.href = "/secret-message")}
                variant="contained"
                sx={{
                  mt: 2,
                  background: "linear-gradient(to right, #ec407a, #fb8c00)",
                  ":hover": { opacity: 0.9 },
                }}
              >
                Create Your Secret Message Link
              </Button>
            </Box>
          ) : (
            <Box>
              <Box mb={3}>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  1. Let's play and have fun with {name || "this user"}.
                </Typography>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  2. Send your message secretly to {name || "this user"}.
                </Typography>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  3. {name || "This user"} will never know who messaged.
                </Typography>
              </Box>

              <TextField
                fullWidth
                value={thought}
                onChange={(e) => setThought(e.target.value)}
                placeholder="Enter Your Thought..."
                variant="outlined"
                InputLabelProps={{ style: { color: "#fff" } }}
                inputProps={{
                  style: {
                    color: "#fff",

                    borderRadius: "4px",
                    fontSize: 24,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#494957",
                    },
                    "&:hover fieldset": {
                      borderColor: "#14d9fd",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#14d9fd",
                    },
                  },
                }}
                multiline
                rows={4}
              />

              {error && (
                <Alert severity="error" sx={{ mb: 3, textAlign: "center" }}>
                  {error}
                </Alert>
              )}

              <Button
                onClick={handleSendMessage}
                disabled={loading}
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  mt: 2,
                  py: 2,
                  backgroundColor: "#14d9fd",
                  "&:hover": { backgroundColor: "#12b4d1" },
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Send"
                )}
              </Button>
              <Link
                to="/secret-message"
                style={{
                  textAlign: "center",
                  display: "block",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginTop: "16px",
                  textDecoration: "none",
                  color: "#fff",
                  border: "1px solid #14d9fd",
                  padding: 4,
                }}
              >
                Generate Your Link
              </Link>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SecretById;
