import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import pdfToword from "../../assets/pdf to word.png";
import wordTopdf from "../../assets/images (1).jfif";
import filecompress from "../../assets/images.png";
import imageToPdf from "../../assets/imagetopdf.png";
import pdfToImage from "../../assets/pdftoimage.png";
import imagecompression from "../../assets/imagecompression.png";
import Modals from "./Modals";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

export default function FileConverters() {
  const [open, setOpen] = React.useState(false);
  const [operationDetails, setOperationDetails] = React.useState({ allowedFormats: [] });

  const handleOpenModal = (tool) => {
    setOperationDetails(tool);
    setOpen(true);
  };

  const cardStyles = {
    width: "100%",
    maxWidth: { xs: "100%", sm: 400, md: 500 },
    height: { xs: "auto", sm: 250 }, // Allow height to be dynamic on smaller screens
    bgcolor: "text.secondary",
    position: "relative",
    padding: "16px",
    color: "white",
    opacity: 0.9, // Add transparency
    "&:hover": {
      opacity: 1, // Full opacity on hover
    },
    border: "2px solid #AB4459",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
  };

  const mediaStyles = {
    objectFit: "contain",
    width: { xs: "80px", sm: "100px" }, // Make the width responsive
    height: { xs: "80px", sm: "100px" }, // Make the height responsive
    position: "absolute",
    top: "10px",
    left: "10px",
    borderRadius: "50%",
  };

  const contentStyles = {
    marginLeft: { xs: "100px", sm: "130px" }, // Adjust margin for mobile
    color: "text.primary",
    padding: "10px",
  };

  const tools = [
    {
      id: "pdfToWord",
      name: "PDF to Word",
      desc: "Convert your PDFs to editable Word documents with ease.",
      img: pdfToword,
      allowedFormats: ["application/pdf"],
      mulitpleFilesUpload: false,
    },
    {
      id: "wordToPdf",
      name: "Word to PDF",
      desc: "Convert your Word documents to secure PDFs effortlessly.",
      img: wordTopdf,
      allowedFormats: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
      mulitpleFilesUpload: false,
    },
    {
      id: "compressImage",
      name: "Reduce Image Size",
      desc: "Compress image files to reduce their size.",
      img: imagecompression,
      allowedFormats: ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/jpg"],
      mulitpleFilesUpload: false,
    },
    {
      id: "compressText",
      name: "Reduce Text File Size",
      desc: "Compress text files to reduce their size.",
      img: filecompress,
      allowedFormats: ["text/plain"],
      mulitpleFilesUpload: false,
    },
    {
      id: "imageToPdf",
      name: "Image to PDF",
      desc: "Quickly combine your images into a single PDF file.",
      img: imageToPdf,
      allowedFormats: ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/jpg"],
      mulitpleFilesUpload: true,
    },
    {
      id: "pdfToImage",
      name: "PDF to Image",
      desc: "Easily turn your PDFs into clear, high-quality images.",
      img: pdfToImage,
      allowedFormats: ["application/pdf"],
      mulitpleFilesUpload: false,
    },
  ];

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 5, md: 10 }, mb: 8 }}> {/* Responsive padding */}
        {/* Descriptive Content */}
        <div
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            textAlign: "center",
            color: "white",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            File Conversion Made Easy
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: 16, sm: 20 }} // Responsive font size
            sx={{ fontWeight: "semibold" }}
          >
            Easily convert your files to the format you need in just a few
            clicks.
          </Typography>
        </div>

        <Grid
          container
          spacing={{ xs: 2, sm: 3 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          {tools.map((tool, index) => (
            <Grid item xs={4} sm={4} md={4} key={tool.id}>
              <Card sx={cardStyles}>
                <CardActionArea onClick={() => handleOpenModal(tool)}>
                  <CardMedia
                    sx={mediaStyles}
                    component="img"
                    image={tool.img}
                    alt={tool.name}
                  />
                  <CardContent sx={contentStyles}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {tool.name}
                    </Typography>
                    <Typography variant="h6">{tool.desc}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modals open={open} setOpen={setOpen} operationDetails={operationDetails} />
    </>
  );
}
