import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import GradientCard from "../../common/GradientCard";

const GameTypes = () => {
  const Games = [
    {
      id: 1,
      img: "https://www.gamesver.com/wp-content/uploads/2022/02/tic-tac-toe-board-game-concept.jpg",
      name: "Tic-Tac-Toe",
      color: "250,71,56,1",
      href: "/tictactoe",
    },
    {
      id: 2,
      img: "https://files.123freevectors.com/wp-content/original/180181-hand-drawn-scribble-sketch-vector-image.jpg",
      name: "Scribbles",
      color: "153,56,250,1",
      href: "/skribbl",
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: { xs: 4, sm: 6, md: 10 },
        mx: { xs: 2, sm: 5, md: 20 },
        mb: { xs: 6, sm: 10 },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: { xs: 3, sm: 4 },
          fontSize: { xs: "22px", sm: "28px", md: "34px" },
          textAlign: "center",
        }}
      >
        Top Games
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, sm: 3, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {Games.map((game) => (
          <Grid item xs={4} sm={4} md={3} key={game.id}>
            <GradientCard tool={game} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GameTypes;
