import React from "react";
import { Box, Typography,Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const MarriageBiodataTemplate = ({ sections, image, setShowTemplate }) => {
    return (
        <Box p={3}>
            <Button
                variant="conatined"
                color="primary"
                onClick={() => setShowTemplate(false)}
                startIcon={<ArrowBack />}
                sx={{ mb: 3 }}
            >
                Back
            </Button>
            <Box textAlign="center" mb={3}>
                {image && (
                    <img
                        src={image}
                        alt="Profile"
                        style={{ width: "150px", height: "150px", borderRadius: "50%" }}
                    />
                )}
            </Box>
            {sections.map((section) => (
                <Box key={section.id} mb={3}>
                    <Typography variant="h6">{section.name}</Typography>
                    <Box mt={1}>
                        {section.fields.map((field, index) => (
                            <Typography key={index}>
                                <strong>{field.label}:</strong> {field.value || "N/A"}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default MarriageBiodataTemplate;
