import React, { useRef, useEffect } from "react";

function Canvas({
  drawing,
  setDrawing,
  brushColor,
  brushSize,
  paths,
  setPaths,
}) {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    const resizeCanvas = () => {
      canvas.width = Math.min(window.innerWidth * 0.9, 600);
      canvas.height = Math.min(window.innerHeight * 0.7, 400);
      const context = canvas.getContext("2d");
      context.lineCap = "round";
      contextRef.current = context;
      redrawCanvas(paths);
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, [paths]);

  const startDrawing = (e) => {
    const { offsetX, offsetY } = getMouseOrTouchPos(e);
    setPaths((prev) => [
      ...prev,
      {
        color: brushColor,
        size: brushSize,
        points: [{ x: offsetX, y: offsetY }],
      },
    ]);
    setDrawing(true);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
  };

  const stopDrawing = () => {
    setDrawing(false);
    contextRef.current.closePath();
  };

  const draw = (e) => {
    if (!drawing) return;
    const { offsetX, offsetY } = getMouseOrTouchPos(e);

    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);

    setPaths((prev) => {
      const updatedPaths = [...prev];
      const currentPath = updatedPaths[updatedPaths.length - 1];
      if (currentPath) {
        currentPath.points.push({ x: offsetX, y: offsetY });
      }
      return updatedPaths;
    });
  };

  const redrawCanvas = (paths) => {
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    paths.forEach(({ color, size, points }) => {
      contextRef.current.strokeStyle = color;
      contextRef.current.lineWidth = size;
      contextRef.current.beginPath();
      points.forEach(({ x, y }) => {
        contextRef.current.lineTo(x, y);
        contextRef.current.stroke();
        contextRef.current.beginPath();
        contextRef.current.moveTo(x, y);
      });
      contextRef.current.closePath();
    });
  };

  const getMouseOrTouchPos = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (e.nativeEvent.touches) {
      const touch = e.nativeEvent.touches[0];
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    }
    return {
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    };
  };

  return (
    <canvas
      ref={canvasRef}
      onMouseDown={startDrawing}
      onMouseUp={stopDrawing}
      onMouseMove={draw}
      onTouchStart={startDrawing}
      onTouchEnd={stopDrawing}
      onTouchMove={draw}
      style={{
        border: "2px solid #ccc",
        backgroundColor: "#2C2C2C",
        display: "block",
        margin: "0 auto",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "100%",

        height: "auto",
      }}
    />
  );
}

export default Canvas;
