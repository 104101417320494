import React from 'react'
import GameBanner from '../components/game/common/GameBanner'
import GameTypes from '../components/game/common/GameTypes'

const Game = () => {
  return (
    <>
    <GameBanner/>
    <GameTypes/>
    </>
  )
}

export default Game