import { Box, CssBaseline } from "@mui/material";
import Router from "./routes/Router";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";

function App() {
  return (
    <>
      <CssBaseline />

      <Navbar />
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <Router />
      </Box>
      <Footer />
    </>
  );
}

export default App;
