import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ComingSoonModal from "./modals/ComingSoonModal";

const GradientCard = ({ tool }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (tool.href) {
      navigate(tool.href); // Navigate if href exists
    } else {
      setOpen(true); // Open modal if href doesn't exist
    }
  };

  return (
    <>
      <Card
        style={{
          position: "relative",
          width: 300,
          height: 450,
          borderRadius: 16,
          overflow: "hidden",
          background: "linear-gradient(to bottom right, #ff007f, #9400ff)",
          cursor: "pointer", // Ensure the cursor appears
        }}
        onClick={handleCardClick} // Fixed event name
      >
        <img
          src={tool.img}
          alt={tool.name} // Use tool.name in alt text for better accessibility
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "brightness(0.7)",
          }}
        />

        <CardContent
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            color: "#fff",
            background: `linear-gradient(to top, rgba(${tool.color}) 20%, rgba(46,21,27,0.5) 60%, rgba(46,21,27,0) 100%)`,
            padding: "20px",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {tool.name}
          </Typography>
        </CardContent>
      </Card>
      <ComingSoonModal open={open} setOpen={setOpen} />
    </>
  );
};

export default GradientCard;
