import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Box, TextField, Button, Typography } from "@mui/material";

const QRCodeGeneration = () => {
    const [text, setText] = useState("Enter your text here");
    const [bgColor, setBgColor] = useState("#ffffff");
    const [fgColor, setFgColor] = useState("#000000");
    const [imageUrl, setImageUrl] = useState("");

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => setImageUrl(e.target.result);
            reader.readAsDataURL(file);
        }
    };

    const downloadQRCode = (format) => {
        const canvas = document.querySelector("canvas");
        const link = document.createElement("a");
        link.download = `qrcode.${format}`;
        if (format === "png" || format === "jpg") {
            link.href = canvas.toDataURL(`image/${format}`);
        } else if (format === "svg") {
            alert("SVG download requires separate implementation. Currently, only PNG/JPG is supported.");
            return;
        }
        link.click();
    };

    return (
        <Box p={3} textAlign="center">
            <Typography variant="h4" gutterBottom>
                QR Code Generator
            </Typography>
            <TextField
                label="Text to Encode"
                value={text}
                onChange={(e) => setText(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Background Color"
                value={bgColor}
                onChange={(e) => setBgColor(e.target.value)}
                fullWidth
                margin="normal"
                type="color"
            />
            <TextField
                label="Foreground Color"
                value={fgColor}
                onChange={(e) => setFgColor(e.target.value)}
                fullWidth
                margin="normal"
                type="color"
            />
            <Button variant="contained" component="label" sx={{ mt: 2 }}>
                Upload Image
                <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageUpload}
                />
            </Button>
            {imageUrl && (
                <Typography variant="body2" color="textSecondary" mt={1}>
                    Image Uploaded Successfully!
                </Typography>
            )}
            <Box display="flex" justifyContent="center" mt={4}>
                <QRCodeCanvas
                    value={text}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    size={256}
                    imageSettings={{
                        src: imageUrl,
                        x: undefined,
                        y: undefined,
                        height: 50,
                        width: 50,
                        excavate: true,
                    }}
                />
            </Box>
            <Box mt={3}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadQRCode("png")}
                    sx={{ mr: 2 }}
                >
                    Download as PNG
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadQRCode("jpg")}
                >
                    Download as JPG
                </Button>
            </Box>
        </Box>
    );
};

export default QRCodeGeneration;
