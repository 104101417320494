import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Snackbar,
  Paper,
} from "@mui/material";
import { Delete as DeleteIcon, Share as ShareIcon } from "@mui/icons-material";
import {
  fetchMessagesAPI,
  deleteMessageAPI,
  resetUserAPI,
} from "../../services/ApiService";
import Confetti from "react-confetti";

const LinkDisplay = ({ link }) => {
  const UserId = localStorage.getItem("userId");
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const messages = await fetchMessagesAPI(UserId);
      setMessages(messages);
    } catch (err) {
      setError("Failed to load messages. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (link) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [link]);

  const deleteMessage = async (messageId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this message?"
    );

    if (!isConfirmed) return;

    try {
      const success = await deleteMessageAPI(messageId);
      if (success) {
        setMessages((prevMessages) =>
          prevMessages.filter((message) => message.id !== messageId)
        );
      }
    } catch (err) {
      setError("Failed to delete the message. Please try again.");
    }
  };

  const handleReset = async () => {
    try {
      const success = await resetUserAPI(UserId);
      if (success) {
        localStorage.removeItem("userId");
        localStorage.removeItem("link");
        window.location.href = "/secret-message";
      }
    } catch (err) {
      setError("Failed to reset user. Please try again.");
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      `Check out my secret message link: ${link}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    fetchMessages();
  }, [UserId]);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box
        sx={{
          maxWidth: 800,
          p: 4,
          mx: "auto",
          mt: 8,
          mb: 8,
          px: { xs: 2, sm: 4 },
        }}
      >
        <Paper
          sx={{
            borderRadius: "10px",
            border: "4px solid gray",
            p: 4,
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              align="center"
              sx={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
              gutterBottom
            >
              Your Link is ready!
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              sx={{
                color: "#fff",
                fontSize: { xs: "1rem", sm: "1.25rem" },
                fontFamily: "cursive",
              }}
              gutterBottom
            >
              Share your link with friends and see their messages.
            </Typography>
            <TextField
              fullWidth
              value={link}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ style: { color: "#fff" } }}
              inputProps={{
                style: {
                  color: "#fff",
                  fontSize: 18,
                  borderRadius: "4px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#494957",
                  },
                  "&:hover fieldset": {
                    borderColor: "#14d9fd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#14d9fd",
                  },
                },
                mt: 4,
                mb: 3,
              }}
              variant="outlined"
            />
            <Button
              fullWidth
              onClick={handleCopyLink}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mb: 3,

                fontSize: { xs: "1rem", sm: "1.25rem" },
                fontWeight: "bold",
              }}
            >
              {copySuccess ? "Copied!" : "Copy Link"}
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleWhatsAppShare}
                variant="contained"
                startIcon={<ShareIcon />}
                sx={{
                  borderRadius: "20px",
                  py: 2,
                  px: 4,
                  mb: { xs: 2, sm: 0 },
                  background: "#387F39",
                  "&:hover": {
                    background: "#90ee90",
                    color: "black",
                  },
                }}
              >
                WhatsApp
              </Button>
              <Button
                onClick={handleReset}
                variant="contained"
                color="error"
                sx={{
                  borderRadius: "20px",
                  py: 2,
                  px: 4,
                  background: "#FF4C4C",
                  "&:hover": {
                    background: "#ffcccb",
                    color: "black",
                  },
                }}
              >
                Reset
              </Button>
            </Box>
          </CardContent>
        </Paper>

        {loading ? (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ backgroundColor: "black", mt: 10 }}>
            <CardContent>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                sx={{
                  fontSize: { xs: "1.25rem", sm: "1.5rem" },
                  color: "#fff",
                }}
              >
                Your Secret Messages Board
              </Typography>
              {error ? (
                <Typography color="error" align="center">
                  {error}
                </Typography>
              ) : messages.length > 0 ? (
                <List>
                  {messages.map((message, index) => (
                    <ListItem
                      key={message.id}
                      divider
                      sx={{
                        backgroundColor: "#1e293b",
                        borderRadius: "8px",
                        mb: 2,
                      }}
                    >
                      <ListItemText
                        primary={
                          <span style={{ color: "#fff", fontSize: "24px" }}>
                            {`${index + 1}. ${message.messages || "No message content"
                              }`}
                          </span>
                        }
                        secondary={
                          <span style={{ color: "gray", fontSize: "18px" }}>
                            {new Date(message.added_date).toLocaleString()}
                          </span>
                        }
                        sx={{ color: "#fff" }}
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="error"
                          onClick={() => deleteMessage(message.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography align="center" sx={{ color: "#fff" }}>
                  No messages
                </Typography>
              )}
            </CardContent>
          </Card>
        )}

        <Snackbar
          open={copySuccess}
          message="Link copied to clipboard"
          autoHideDuration={2000}
          onClose={() => setCopySuccess(false)}
        />
      </Box>
    </>
  );
};

export default LinkDisplay;
