import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "./css/style.css";

import { Pagination, Autoplay } from "swiper/modules";
import GradientCard from "../common/GradientCard";
import { toolsData } from "../../mock/toolsData";
import { Box, Typography } from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

export default function ToolsSlider() {
  return (
    <>
      <Box
        sx={{
          mt: 8,
        }}
      >
        <Box
          sx={{
            mb: 5,
            px: 6,
          }}
        >
          <Typography variant="h5" component="h2">
            Trending <LocalFireDepartmentIcon sx={{ color: "yellow" }} />
          </Typography>
        </Box>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2000, 
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {toolsData.map((tool, index) => (
            <SwiperSlide key={index} style={{marginBottom:"20px"}}>
              <GradientCard tool={tool} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
}
