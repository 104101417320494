import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Message,
  SportsEsports,
  Build,
  Menu as MenuIcon,
  Close,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const menuItems = [
    {
      label: "Home",
      icon: <HomeIcon />,
      link: "/",
    },
    {
      label: "Secret Message",
      icon: <Message />,
      link: "/secret-message",
    },
    {
      label: "Games",
      icon: <SportsEsports />,
      link: "/game",
    },
    {
      label: "Tools",
      icon: <Build />,
      link: "/converter",
    },
  ];

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mx: 8,
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        href="/"
        disableRipple
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "text.primary" }}
        >
          Rapid
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "text.secondary", ml: 1 }}
        >
          Hub
        </Typography>
      </IconButton>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#10162d",
          borderRadius: "50px",
          padding: "10px 20px",
          width: "50%",
          boxShadow: 3,
          display: { xs: "none", md: "flex" },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { xs: "0 8px", sm: "0 16px" },
          }}
        >
          {/* Desktop Menu */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {/* {menuItems.map((item, index) => (
              <IconButton
                key={index}
                color="inherit"
                href={item.link}
                disableRipple
                sx={{
                  mx: 2,
                  padding: "10px 20px",
                  color: "text.primary",
                  fontSize: "20px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#8250CA",
                    color: "text.primary",
                  },
                  "&.Mui-selected": { backgroundColor: "text.secondary" },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MuiListItemIcon
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    {item.icon}
                  </MuiListItemIcon>
                  <Typography variant="h6">{item.label}</Typography>
                </Box>
              </IconButton>
            ))} */}
          </Box>

          {/* Mobile Menu Icon */}
        </Toolbar>
      </AppBar>
      <IconButton
        color="inherit"
        edge="end"
        aria-label="menu"
        onClick={toggleMobileMenu}
        sx={{
          display: { xs: "flex", md: "none" }, // Show only on small screens
          backgroundColor: "transparent", // Remove background color
          "&:hover": {
            backgroundColor: "transparent", // Remove hover background color
          },
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer for Mobile View */}
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            backgroundColor: "#0E0120",
            color: "white",
            boxShadow: 3,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <IconButton
            edge="start"
            color="inherit"
            href="/"
            disableRipple
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Rapid
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "text.secondary", ml: 1 }}
            >
              Hub
            </Typography>
          </IconButton>
          <IconButton onClick={toggleMobileMenu}>
            <Close sx={{ color: "white" }} />
          </IconButton>
        </Box>
        {/* Only show menu items in mobile drawer */}
        <List>
          {menuItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                href={item.link}
                sx={{
                  "&:hover": { backgroundColor: "#8250CA" },
                }}
              >
                <MuiListItemIcon sx={{ color: "white" }}>
                  {item.icon}
                </MuiListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Navbar;
