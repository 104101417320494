import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#14d9fd",
    },
    secondary: {
      main: "#12b4d1",
    },
    text: {
      primary: "#fff",
      secondary: "#8250CA",
    },
    background: {
      default: "#0c1121",
      secondary: "#8250CA",
      paper: "#161e3b",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#14d9fd",
          color: "#0C1121",
          borderRadius: "8px",
          padding: "8px 16px",
          "&:hover": {
            backgroundColor: "#12b4d1",
          },
        },
        contained: {
          backgroundColor: "#14d9fd",
          color: "#0C1121",
          "&:hover": {
            backgroundColor: "#12b4d1"
          },
        },
        outlined: {
          borderColor: "#14d9fd",
          color: "#14d9fd",
          "&:hover": {
            borderColor: "#12b4d1",
            backgroundColor: "rgba(18, 180, 209, 0.1)",
          },
        },
        text: {
          color: "#0C1121",
          "&:hover": {
            color: "#14d9fd",
            backgroundColor: "rgba(20, 217, 253, 0.1)",
          },
        },
        error: {
          backgroundColor: "#f44336",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
          },
        },
        outlinedError: {
          borderColor: "#f44336",
          color: "#f44336",
          "&:hover": {
            borderColor: "#d32f2f",
            backgroundColor: "rgba(244, 67, 54, 0.1)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "#161e3b",
            color: "#fff",
            borderRadius: "8px",

          },
          "& .MuiInputLabel-root": {
            color: "#fff",
          },
          "& .MuiInputBase-root.Mui-focused": {
            backgroundColor: "#0c1121",
            borderColor: "#14d9fd",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#14d9fd",
          "&:hover": {
            backgroundColor: "#12b4d1",
            color: "black",
          },
        },
      },
    },
  },
});
