import axios from "axios";
import { DataURls } from "../components/common/urls";

const BASE_URL = "https://rapidgameshub.onrender.com";

export const compressFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(`${BASE_URL}/fileCompress/compressFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Specify response type as blob
      });
      return response.data; // Return the file blob
    } catch (error) {
      console.error("Error compressing file:", error);
      throw error;
    }
  };
  

  export const PDFtoWord = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(`${BASE_URL}/fileConvertor/pdfToWord`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Specify response type as blob
      });
      return response.data; // Return the file blob
    } catch (error) {
      console.error("Error compressing file:", error);
      throw error;
    }
  };

  export const WordToPDF = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(`${BASE_URL}/fileConvertor/wordToPdf`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Specify response type as blob
      });
      return response.data; // Return the file blob
    } catch (error) {
      console.error("Error compressing file:", error);
      throw error;
    }
  };