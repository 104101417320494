import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const GameBanner = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #3f51b5, #ff4081)",
        color: "white",
        padding: { xs: "16px", sm: "20px", md: "40px" }, // Adjust padding for mobile
        borderRadius: "8px",
        overflow: "hidden",
        mt: { xs: 6, sm: 10 }, // Adjust margin-top for mobile
        mx: { xs: 2, sm: 5, md: 20 }, // Adjust margin-x for mobile
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={3} // More spacing for better clarity on mobile
      >
        {/* Left Side: Heading */}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "22px", sm: "32px", md: "54px" },
              textAlign: { xs: "center", sm: "left" }, // Center text on mobile
            }}
          >
            Welcome to GameZone!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: "10px",
              fontSize: { xs: "14px", sm: "16px", md: "28px" },
              textAlign: { xs: "center", sm: "left" }, // Center text on mobile
            }}
          >
            Dive into an immersive gaming experience.
          </Typography>
        </Grid>

        {/* Right Side: Image */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" }, // Center image on mobile
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src="https://static.vecteezy.com/system/resources/previews/002/304/431/large_2x/game-zone-entertainment-banner-game-logo-illustration-free-vector.jpg"
            alt="Game Banner"
            sx={{
              width: { xs: "100%", sm: "80%", md: "70%" },
              maxWidth: "500px",
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameBanner;
